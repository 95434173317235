import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Field extends Component {
  static propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    validate: PropTypes.func,
  };

  state = {
    value: this.props.value,
    error: false,
  };

  componentWillReceiveProps(update) {
    this.setState({ value: update.value });
  }

  onChange = (e) => {
    const name = this.props.name;
    const value = e.target.value;
    const error = this.props.validate ? this.props.validate(value) : false;

    this.setState({ value, error });

    this.props.onChange({ name, value, error });
  };

  withError = (element) => {
    return (
      [
        element,
        <span style={{ color: 'red' }}>{ this.state.error }</span>,
      ]
    );
  }

  render() {
    if (this.props.type === 'select') {
      return this.withError(
        <select
          id={this.props.id}
          name={this.props.name}
          value={this.state.value}
          className="font-alt form-control required"
          onChange={this.onChange}
        >
          <option value="">Choose Your Services</option>
          <option value="Holistic Health and Wellness">Holistic Health and Wellness</option>
          <option value="Career and Life Purpose">Career and Life Purpose</option>
          <option value="Relationship Building and Transition">Relationship Building and Transition</option>
          <option value="Workshops">Workshops</option>
        </select>
      );
    }
    else if (this.props.type === 'textarea') {
      return this.withError(
        <textarea
          id={this.props.id}
          name={this.props.name}
          value={this.state.value}
          placeholder="Message"
          className="font-alt form-control required"
          onChange={this.onChange}
          rows="6"
        ></textarea>
      );
    }
    return this.withError(
      <input type={this.props.type === 'email' ? 'email' : 'text'}
        id={this.props.id}
        name={this.props.name}
        value={this.state.value}
        placeholder={this.props.placeholder}
        className="font-alt form-control required email"
        onChange={this.onChange}
      />
    );
  }
}

export default Field;
