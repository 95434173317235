import React, { Component } from 'react';
import Field from './Field';
import isEmail from 'validator/lib/isEmail';
import Client from './Client';
import './ContactForm.css';

class ContactForm extends Component {
  state = {
    fields: {
      name: '',
      email: '',
      service: '',
      message: '',
    },
    fieldErrors: {},
    submitStatus: 'none',
  };

  componentDidMount = () => {
    window.emailjs.init(process.env.REACT_APP_EMAILJS_KEY)
  }

  onFormSubmit = async e => {
    e.preventDefault();
    this.setState({ submitStatus: 'submitting' });
    window.analytics.track('Contact Form Attempted', this.state);

    if (this.validate()) {
      this.setState({ submitStatus: 'none' });
      return;
    }

    const templateParams = {
      subject: '[Blazing Sun Life] New Contact',
      html: `
      <h2>New contact ${this.state.fields.name}</h2>
      <p>
        <ul>
          <li><b>Name</b>: ${this.state.fields.name}</li>
          <li><b>E-mail</b>: ${this.state.fields.email}</li>
          <li><b>Service</b>: ${this.state.fields.service}</li>
          <li><b>Message</b>: ${this.state.fields.message}</li>
        </ul>
      </p>
      `
    }

    await new Promise((resolve, reject) => {

      window.emailjs.send(
        'default_service',
        'blazing_sun_life_contact_form',
        templateParams,
      ).then(response => {
        console.log('E-mail notification sent', response.status, response.text)
        resolve(response)
      }, error => {
        console.log('E-mail notification send failed', error)
        reject(error)
      })

    })

    Client.submit_contact(this.state.fields, () => {
      console.log('Contact submitted to registry')

      window.analytics.identify(this.state.fields.email)
      window.analytics.track('Contact Form Submitted', this.state)

      this.setState({
        fields: {
          name: '',
          email: '',
          service: '',
          message: '',
        },
        submitStatus: 'submitted',
      })

    })

  };

  onInputChange = (e) => {
    const fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  onInputChange = ({ name, value, error }) => {
    const fields = this.state.fields;
    const fieldErrors = this.state.fieldErrors;

    fields[name] = value;
    fieldErrors[name] = error;

    this.setState({ fields, fieldErrors });
  };

  validate = () => {
    const submission = this.state.fields;
    const fieldErrors = this.state.fieldErrors;
    const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);

    if (!submission.name) return true;
    if (!submission.email) return true;
    if (!submission.service) return true;
    if (!submission.message) return true;
    if (errMessages.length) return true;

    return false;
  };

  render() {
    if (this.state.submitStatus === 'submitted') {
      return (
        [
          <h3 className="font-alt title-extra-large">Thank You!</h3>,
          <span className="bg-base-color mt-4 sep-line-extra-thick"></span>,
          <p className="mt-4 title-small">I look forward to reading your submission! I will get back to you within 24 hours</p>
        ]
      );
    }
    return (
      [
        <h3 className="font-alt title-extra-large">Ready to reach for your goals?</h3>,
        <span className="bg-base-color mt-4 sep-line-extra-thick"></span>,
        <p className="mt-4 title-small">Give me a call or drop a line here with any questions. Click the Schedule button to schedule a FREE 30 minute consultation!</p>,
        <form onSubmit={this.onFormSubmit} id="form-contact" className="mt-5">
          <div className="form-group">
            <Field
              type="text"
              key="name"
              id="name"
              name="name"
              placeholder="Your Name"
              onChange={this.onInputChange}
              validate={(val) => (val ? false : 'Name Required')}
            />
          </div>
          <div className="form-group">
            <Field
              type="email"
              key="email"
              id="email"
              name="email"
              placeholder="Your Email"
              onChange={this.onInputChange}
              validate={(val) => (isEmail(val) ? false : 'Invalid Email')}
            />
          </div>
          <div className="form-group">
            <Field
              type="select"
              key="service"
              id="service"
              name="service"
              onChange={this.onInputChange}
              validate={(val) => (val ? false : 'Service Required')}
            />
          </div>
          <div className="form-group">
            <Field
              type="textarea"
              key="message"
              id="message"
              name="message"
              placeholder="Message"
              onChange={this.onInputChange}
              validate={(val) => (val ? false : 'Message Required')}
            />
          </div>
          <span className="d-block font-alt letter-spacing-1 text-small text-uppercase">*Please complete all fields correctly</span>
          <div
            className="loader"
            style={{
              display: (this.state.submitStatus === 'submitting' ? 'block' : 'none')
            }}
          ></div>
          <input
            type="submit"
            className="btn btn-shadow btn-base-color mt-4 text-uppercase"
            value="Send Message"
            disabled={this.state.submitStatus !== 'none'}
          />
        </form>
      ]
    );
  }
}

export default ContactForm;
