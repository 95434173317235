/* eslint-disable no-undef */
const querystring = require('querystring');

const signupFormUrl = 'https://script.google.com/macros/s/AKfycbyJptIfDLdoSfOn1c1k14plWi5sT19ZLG3VzckuGXj9FYcS2i5Y/exec'

function submit_contact(params, cb) {
  const paramsWithTimestamp = {
    ...params,
    timestamp: new Date(Date.now()).toISOString(),
  }

  return fetch(signupFormUrl + '?' + querystring.stringify(paramsWithTimestamp), {
    method: 'GET',
  }).then(checkStatus)
    .then(parseJSON)
    .then(cb)
    .catch((error) => console.log(error.message))
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    console.log(error); // eslint-disable-line no-console
    throw error;
  }
}

function parseJSON(response) {
  return response.json();
}

const Client = { submit_contact };
export default Client;
